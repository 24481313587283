import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Text,
} from "recharts";

export default function DynamicRanking(props) {
  const [data, setData] = useState("");
  const [ticks, setTicks] = useState("");
  const [interval, setTickInterval] = useState(9);
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);

  useEffect(() => {
    if (props.data) {
      const propsArr = props.data.placements;
      const arr = [];
      const values = [];

      if (propsArr.length < 20) {
        setTickInterval(0);
      } else if (propsArr.length < 20) {
        setTickInterval(9);
      }

      propsArr.forEach((element, index) => {
        var elem = {};
        elem.competition = `competition ${index}`;
        elem.rank = element.Placement;
        arr.push(elem);
        values.push(element.Placement);
        element.CompetitiorRankings.forEach((el) => {
          values.push(el);
        });
      });
      setData(arr);

      const uniq = [...new Set(values)];

      const min = Math.min(...values);
      const max = Math.max(...values);

      const minYAxis = Math.floor(min - 1);
      const maxYAxis = Math.ceil(max + 1);

      setMinValue(minYAxis);
      setMaxValue(maxYAxis);

      setTicks(uniq.sort());
    }
  }, [props.data]);

  const CustomizedLabelB = () => {
    return (
      <Text
        x={0}
        y={0}
        dx={-150}
        dy={40}
        textAnchor="start"
        width={180}
        transform="rotate(-90)">
        Position
      </Text>
    );
  };

  return (
    <div>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          width={500}
          height={300}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            // type="number"
            dataKey="competition"
            label={{
              value: "Simulation",
              position: "insideBottom",
              dy: 10,
            }}
            scale="auto"
            domain={["dataMin", "dataMax"]}
            //   tickSize={10}
            interval={interval}
            allowDecimals={false}
            angle={-15}
            textAnchor="end"
            height={60}
          />
          <YAxis
            reversed="true"
            type="number"
            domain={[minValue, maxValue]}
            ticks={ticks ? ticks : null}
            allowDecimals={false}
            scale="auto"
            tickSize={1}
            interval={0}
            label={<CustomizedLabelB />}
          />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="rank"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
            dot={{ stroke: "red", strokeWidth: 3 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
