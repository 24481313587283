import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Text,
  ResponsiveContainer,
} from "recharts";

export default function DuopolyBar(props) {
  const [data, setData] = useState("");

  useEffect(() => {
    if (props.data) {
      var result = props.data.placements.reduce(
        (acc, o) => ((acc[o.Placement] = (acc[o.Placement] || 0) + 1), acc),
        {}
      );
      var newresult = Object.keys(result).map((e) => ({
        place: Number(e),
        frequency: result[e],
      }));

      newresult.forEach(
        (el) =>
          (el.frequency =
            (el.frequency / props.data.placements.length).toFixed(2) * 100)
      );
      newresult.sort((a, b) => (a.place > b.place ? 1 : -1));
      setData(newresult);
    }
  }, [props.data]);

  const CustomizedLabelB = () => {
    return (
      <Text
        x={0}
        y={0}
        dx={-150}
        dy={30}
        textAnchor="start"
        width={180}
        transform="rotate(-90)">
        Frequency
      </Text>
    );
  };

  return (
    <div>
      <ResponsiveContainer>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="place"
            label={{
              value: "Placement",
              position: "insideBottom",
              dy: 10,
            }}></XAxis>
          <YAxis
            dataKey="frequency"
            label={<CustomizedLabelB />}
            tickFormatter={(tick) => {
              return `${tick}%`;
            }}
            domain={[0, 125]}
            ticks={[0, 25, 50, 75, 100, 125]}
          />
          <Tooltip
            labelFormatter={(place) => "placement: " + place}
            formatter={(value) => value + "%"}
          />
          <Bar dataKey="frequency" fill="#141446" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
