import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Nav from "../components/MainNav";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  Collapse,
  CircularProgress,
} from "@material-ui/core";
import DatePicker from "react-datepicker";
import DynamicRanking from "../components/DynamicRanking";
import { withRouter } from "react-router-dom";
import DynamicBar from "../components/DynamicBar";
import DynamicTable from "../components/DynamicTable";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";
import { flattenArray, downloadFile } from "../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  logo: {
    width: "100px",
    height: "auto",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  appbar: {
    position: "relative",
    top: "-10px",
    width: "97%",
    margin: "0 auto",
  },
  appbarTitle: { color: "#ffff" },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paddedHeading: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  animatedIcon: {
    height: "80px",
    width: "auto",
  },
  barHeading: {
    paddingBottom: theme.spacing(4),
    marginTop: "3rem",
  },
  paperBar: {
    padding: theme.spacing(7),
    textAlign: "center",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  btnContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },

  link: {
    textDecoration: "none !important",
  },
  btnBack: {
    backgroundColor: "#141446",
    color: "#ffff",
    "&:hover": {
      backgroundColor: "#2a2a7a",
      color: "#ffff",
    },
  },
}));

function Dynamic(props) {
  const classes = useStyles();
  const dateProp = JSON.parse(localStorage.getItem("selectedDate"));
  const chosenDateFormatted = moment(dateProp).format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(new Date());
  const [highlightedDates, setHighlightedDates] = useState([]);
  const [formattedHighlightedDates, setFormattedHighlightedDates] = useState(
    []
  );

  const [userResults, setUserResults] = useState([]);
  const [rankingResults, setRankingResults] = useState([]);
  const [noData, setNoData] = useState(false);
  const [resultFiles, setResultFiles] = useState({});
  const [loading, setLoading] = useState(false);

  const [errRate, setErrRate] = useState(0);
  const [avgRequestTime, setAvgRequestTime] = useState(0);

  const [errorTxt, setErrorTxt] = useState("");
  const [detailedResults, setDetailedResults] = useState("");

  //get highligthed dates
  useEffect(() => {
    document.title = "Dynamic details";
    const date = JSON.parse(localStorage.getItem("selectedDate"));
    const chosenDate = moment(date).format("YYYY-MM-DD");
    setStartDate(new Date(chosenDate));

    Auth.currentAuthenticatedUser().then((user) => {
      let id = user.username;
      const apiName = "ApiGatewayRestApi";
      const path = "/files";
      const myInit = {
        response: true,
        queryStringParameters: { id: id },
      };
      API.get(apiName, path, myInit)
        .then((response) => {
          const progressData = JSON.parse(response.data[4]);
          const dates = [];
          const parsedDates = [];
          progressData.forEach((element) => {
            dates.push(element.competition_date);
          });
          dates.forEach((el) => {
            let parts = el.split("-");
            let mydate = new Date(parts[0], parts[1] - 1, parts[2]);
            parsedDates.push(mydate);
          });
          setHighlightedDates(parsedDates);
          const formattedHighlightedDates = parsedDates.map((el) =>
            moment(el).format("YYYY-MM-DD")
          );
          setFormattedHighlightedDates(formattedHighlightedDates);
        })
        .catch((error) => {
          console.log(error.response);
        });
    });
  }, []);

  //populate on load
  useEffect(() => {
    setLoading(true);
    if (formattedHighlightedDates.includes(chosenDateFormatted)) {
      Auth.currentAuthenticatedUser().then((user) => {
        let id = user.username;
        const apiName = "ApiGatewayRestApi";
        const path = "/results";
        const myInit = {
          response: true,
          queryStringParameters: {
            date: chosenDateFormatted,
            id: id,
          },
        };
        API.get(apiName, path, myInit)
          .then((response) => {
            let data = response.data;
            setLoading(false);
            setRankingResults(data[1]);
            setUserResults(data[0]);
            try {
              setErrRate(data[0].dynamic.error_rate.ErrorRate * 100).toFixed(2);
              setAvgRequestTime(
                data[0].dynamic.avg_request_time.AverageRequestTime.toFixed(2)
              );
            } catch {
              console.info("Incomplete Data");
            }
            setResultFiles(flattenArray(data[2]));
          })
          .catch((error) => {
            setUserResults(null);
            console.log(error.response);
          });
      });
    }
  }, [chosenDateFormatted, formattedHighlightedDates]);

  const onDateSelect = (thisdate) => {
    const chosenDate = moment(thisdate).format("YYYY-MM-DD");

    setUserResults([]);
    setRankingResults([]);
    setErrorTxt("");
    setDetailedResults("");

    if (formattedHighlightedDates.includes(chosenDate)) {
      setLoading(true);
      Auth.currentAuthenticatedUser().then((user) => {
        let id = user.username;
        const apiName = "ApiGatewayRestApi";
        const path = "/results";
        const myInit = {
          response: true,
          queryStringParameters: {
            date: chosenDate,
            id: id,
          },
        };
        API.get(apiName, path, myInit)
          .then((response) => {
            let data = response.data;
            setLoading(false);
            setRankingResults(data[1]);
            setUserResults(data[0]);
            try {
              setErrRate(data[0].dynamic.error_rate.ErrorRate * 100).toFixed(2);
              setAvgRequestTime(
                data[0].dynamic.avg_request_time.AverageRequestTime.toFixed(2)
              );
            } catch {
              console.info("Incomplete Data");
            }
            setResultFiles(flattenArray(data[2]));
          })
          .catch((error) => {
            setUserResults(null);
            console.log(error.response);
          });
      });
    } else {
      setNoData(true);
    }
  };

  return (
    <div className={classes.root}>
      <Nav />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {noData ? (
            <Collapse in={noData}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setNoData(false);
                    }}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }>
                <Typography variant="h6" align="left">
                  No data for the selected date
                </Typography>
              </Alert>
            </Collapse>
          ) : null}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom align="left">
                Your selected date:
              </Typography>
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  onDateSelect(date);
                }}
                highlightDates={highlightedDates}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}>
              <Link
                to={{
                  pathname: "/results",
                }}
                className={classes.link}>
                <Button variant="contained" className={classes.btnBack}>
                  Go back
                </Button>
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <Typography variant="h5" gutterBottom align="left">
                      Dynamic
                    </Typography>
                    <Grid
                      container
                      spacing={2}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <Grid item xs={12} sm={5}>
                        <Typography variant="h6" gutterBottom align="left">
                          Percentage competitions won
                        </Typography>
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          <DynamicBar
                            data={
                              userResults && userResults.dynamic
                                ? userResults.dynamic
                                : null
                            }
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        className={classes.btnContainer}>
                        <div>
                          <div>
                            <Typography variant="h5" gutterBottom align="left">
                              Error rate
                            </Typography>
                            <Typography variant="h6" gutterBottom align="left">
                              {errRate}%
                            </Typography>
                          </div>
                          <div>
                            <Typography variant="h5" gutterBottom align="left">
                              Average request time
                            </Typography>
                            <Typography variant="h6" gutterBottom align="left">
                              {avgRequestTime} seconds
                            </Typography>
                          </div>
                        </div>

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            downloadFile(resultFiles, 'dynamic_errors.csv')
                          }}>
                          Download Error txt
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginTop: "1rem" }}
                          className={classes.btnDetail}
                          onClick={() => {
                            downloadFile(resultFiles, 'dynamic_competition_details.csv')
                          }}>
                          Download detailed results
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <DynamicTable data={rankingResults} />
                  )}
                </Grid>

                <Grid item md={5} style={{ marginTop: "1rem" }}>
                  <Typography variant="h6" gutterBottom align="left">
                    The graph below shows how you ranked each competition.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <Typography variant="h6" gutterBottom align="left">
                      Ranking for this challenge
                    </Typography>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <DynamicRanking
                        data={
                          userResults && userResults.dynamic
                            ? userResults.dynamic
                            : null
                        }
                      />
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}

export default withRouter(Dynamic);
