import React, { useState, useEffect } from "react";
import { Typography, Paper } from "@material-ui/core";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import MaterialTable from "material-table";
import { forwardRef } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function Rankings(props) {
  const [duopolyData, setDuopolyData] = useState([]);
  //const [oligopolyData, setOligopolyData] = useState([]);
  //const [dynamicData, setDynamicData] = useState([]);

  const roundValues = (item) => ({
    ...item,
    AverageRanking: Number(item.AverageRanking.toFixed(2)),
  });

  useEffect(() => {
    if (props.data.duopoly) {
      const duoProps = props.data.duopoly;
      const formattedDuoData = duoProps.map(roundValues);
      setDuopolyData(formattedDuoData);
    }

    // if (props.data.oligopoly) {
    //   const oliProps = props.data.oligopoly;
    //   const formattedOliData = oliProps.map(roundValues);
    //   setOligopolyData(formattedOliData);
    // }

    // if (props.data.dynamic) {
    //   const dynamicProps = props.data.dynamic;
    //   const formattedDynData = dynamicProps.map(roundValues);
    //   setDynamicData(formattedDynData);
    // }
  }, [props]);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom align="left">
        Rankings
      </Typography>
      <Tabs>
        <TabList>
          <Tab>Duopoly</Tab>
          {/* <Tab>Oligopoly</Tab>
          <Tab>Dynamic</Tab> */}
        </TabList>

        <TabPanel>
          <MaterialTable
            icons={tableIcons}
            title="Ranking"
            style={{ boxShadow: "none" }}
            component={{
              Container: (props) => <Paper {...props} elevation={0} />,
            }}
            columns={[
              {
                title: "Overall ranking",
                field: "Ranking",
                type: "numeric",
              },
              { title: "Name", field: "Name" },
              {
                title: "Avg Ranking per simulation",
                field: "AverageRanking",
                type: "numeric",
              },
              {
                title: "Avg Revenue per simulation",
                field: "AverageRevenue",
                type: "currency",
                currencySetting: {
                  currencyCode: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                },
              },
            ]}
            data={duopolyData}
            options={{
              paging: true,
              showTitle: false,
            }}
          />
        </TabPanel>
        {/* <TabPanel>
          <MaterialTable
            icons={tableIcons}
            style={{ boxShadow: "none" }}
            title="Ranking"
            columns={[
              {
                title: "Overall Ranking",
                field: "Ranking",
                type: "numeric",
              },
              { title: "Name", field: "Name" },
              {
                title: "Avg Ranking per simulation",
                field: "AverageRanking",
                type: "numeric",
              },
              {
                title: "Avg Revenue per simulation",
                field: "AverageRevenue",
                type: "currency",
                currencySetting: {
                  currencyCode: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                },
              },
            ]}
            data={oligopolyData}
            options={{
              paging: true,
              showTitle: false,
            }}
          />
        </TabPanel>
        <TabPanel>
          <MaterialTable
            icons={tableIcons}
            style={{ boxShadow: "none" }}
            title="Ranking"
            columns={[
              {
                title: "Overall Ranking",
                field: "Ranking",
                type: "numeric",
              },
              { title: "Name", field: "Name" },
              {
                title: "Avg Ranking per simulation",
                field: "AverageRanking",
                type: "numeric",
              },
              {
                title: "Avg Revenue per simulation",
                field: "AverageRevenue",
                type: "currency",
                currencySetting: {
                  currencyCode: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                },
              },
            ]}
            data={dynamicData}
            options={{
              paging: true,
              showTitle: false,
            }}
          />
        </TabPanel> */}
      </Tabs>
    </React.Fragment>
  );
}
