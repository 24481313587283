import React from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
    pageFiller:{
        width: '100%',
        height: '100%',
        background: 'white',
        opacity: 0.95,
        textAlign: 'center',
        position: 'absolute',
        zIndex: 1
    }
}))

function CircularLoadingIcon({text}) {
    const classes = useStyles()
    return (
        <div className={classes.pageFiller}>
            <CircularProgress style={{width:'5rem', height:'5rem', marginTop:'5%'}}/>
            {text&&
            <p>{text}</p>
            }
        </div>
    )
}

export default CircularLoadingIcon
