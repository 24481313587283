import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import Duopolybox from "./DuopolyBox";
import Oligopolybox from "./OligopolyBox";
import Dynamicbox from "./DynamicBox";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: "2rem",
  },
}));

export default function ResultBoxes(props) {
  const duopolyResults = props.results.duopoly;
  //const oligopolyResults = props.results.oligopoly;
  //const dynamicResults = props.results.dynamic;

  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid
        container
        spacing={2}
        justify="center"
        alignItems="center"
        className={classes.wrapper}>
        <Grid item xs={10}>
          <Typography variant="h5" align="center">
            Test results
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Duopolybox duopolyResults={duopolyResults} />
        </Grid>
        {/* <Grid item xs={12} md={3}>
          <Oligopolybox oligopolyResults={oligopolyResults} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Dynamicbox dynamicResults={dynamicResults} />
        </Grid> */}

        
      </Grid>
    </React.Fragment>
  );
}
