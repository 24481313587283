import React from "react";
import "./App.css";
import Amplify, {Auth} from "aws-amplify";
import { AmplifyAuthenticator, AmplifySignUp } from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import awsconfig from "./aws-exports";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./containers/Dashboard";
import Results from "./containers/Results";
import FileManager from "./containers/FileManager";
import Duopoly from "./containers/Duopoly";
import Oligopoly from "./containers/Oligopoly";
import Dynamic from "./containers/Dynamic";
import Grid from "@material-ui/core/Grid";
import LoggedOutNav from "./components/LoggedOutNav";
import { makeStyles } from "@material-ui/core/styles";
import bgImage from "./assets/form-background.jpg";

Amplify.configure({
  ...awsconfig,
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: 'eu-central-1:240ac062-5113-4bd7-9930-1795d1ac48fa',
    mandatorySignIn: true,
    // // REQUIRED - Amazon Cognito Region
    // region: 'eu-central-1',

    // // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
    // // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',

    // // OPTIONAL - Amazon Cognito User Pool ID
    // userPoolId: 'eu-central-1_I4q22yrqp	',

    // // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    // userPoolWebClientId: '1h49iem8kb1beeiv5knqndi3u',
  },
  API: {
    endpoints:[
      {
        name: 'ApiGatewayRestApi',
        endpoint: 'https://vbiczllkoi.execute-api.eu-central-1.amazonaws.com/dev/'
      }
    ]
  }
});
Auth.configure(awsconfig)

const useStyles = makeStyles((theme) => ({
  toast: { backgroundColor: "red" },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "60%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  background: {
    position: "fixed",
    top: "0px",
    left: "0px",
    width: "50%",
    height: "100%",
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    zIndex: -1,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  colorOverlay: {
    height: "100%",
    background: `rgba(196, 180, 227, 0.34)`,
  },
}));

const AuthStateApp = () => {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  const classes = useStyles();

  React.useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <Router>
      <div className="App">
        <Switch>
          <Route
            exact
            path="/"
            render={() => <Dashboard user={user.username} />}
          />
          <Route exact path="/results" render={() => <Results />} />
          <Route exact path="/files" render={() => <FileManager />} />
          <Route exact path="/duopoly" render={() => <Duopoly />} />
          {/* <Route exact path="/oligopoly" render={() => <Oligopoly />} />
          <Route exact path="/dynamic" render={() => <Dynamic />} /> */}
        </Switch>
      </div>
    </Router>
  ) : (
    <div>
      <LoggedOutNav />
      <div className={classes.background}>
        <div className={classes.colorOverlay}></div>
      </div>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={6}></Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          className={classes.colorOverlay}>
          <div className={classes.paper}>
            <AmplifyAuthenticator>
              <AmplifySignUp
                slot="sign-up"
                usernameAlias="email"
                formFields={[
                  {
                    type: "given_name",
                    label: "First Name*",
                    required: true,
                  },
                  {
                    type: "family_name",
                    label: "Last Name*",
                    required: true,
                  },
                  {
                    type: "custom:company",
                    label: "Affiliation (Company/University)",
                    required: true,
                  },
                  {
                    type: "email",
                    label: "Email* (it will be your username to log in)",
                    required: true,
                  },
                  {
                    type: "password",
                    label: "Password*",
                    hint:
                      "Min 8 Characters. Must include a number, a special character, an uppercase and a lowercase letter.",
                    required: true,
                  },
                ]}
              />
            </AmplifyAuthenticator>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AuthStateApp;
