import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Nav from "../components/MainNav";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  Collapse,
  CircularProgress,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DatePicker from "react-datepicker";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Alert } from "@material-ui/lab";

import DuopolyBar from "../components/DuopolyBar";
import DuopolyRanking from "../components/DuopolyRanking";
import DuoTable from "../components/DuoTable";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import {flattenArray, downloadFile} from "../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  logo: {
    width: "100px",
    height: "auto",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  appbar: {
    position: "relative",
    top: "-10px",
    width: "97%",
    margin: "0 auto",
  },
  appbarTitle: { color: "#ffff" },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paddedHeading: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  animatedIcon: {
    height: "80px",
    width: "auto",
  },
  barHeading: {
    paddingBottom: theme.spacing(4),
    marginTop: "3rem",
  },
  paperBar: {
    padding: theme.spacing(7),
    textAlign: "center",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  btnContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignContent: "flex-start",
  },

  link: {
    textDecoration: "none !important",
  },
  btnBack: {
    backgroundColor: "#141446",
    color: "#ffff",
    "&:hover": {
      backgroundColor: "#2a2a7a",
      color: "#ffff",
    },
  },
}));

function Duopoly() {
  const classes = useStyles();
  const dateProp = JSON.parse(localStorage.getItem("selectedDate"));
  const chosenDateFormatted = moment(dateProp).format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(new Date());
  const [highlightedDates, setHighlightedDates] = useState([]);
  const [formattedHighlightedDates, setFormattedHighlightedDates] = useState(
    []
  );

  const [loading, setLoading] = useState(false);
  const [userResults, setUserResults] = useState("");
  const [rankingResults, setRankingResults] = useState([]);
  const [noData, setNoData] = useState(false);
  const [resultFiles,setResultFiles] = useState({})

  const [competitorList, setCompetitorList] = useState("");
  const [competitor, setCompetitor] = useState("");

  const [stockOutFrequency, setStockOutFrequency] = useState(0);
  const [errRate, setErrRate] = useState(0);
  const [avgRequestTime, setAvgRequestTime] = useState(0);

  const [errorTxt, setErrorTxt] = useState("");
  const [detailedResults, setDetailedResults] = useState("");

  //get highligthed dates
  useEffect(() => {
    document.title = "Duopoly details";
    const date = JSON.parse(localStorage.getItem("selectedDate"));
    const chosenDate = moment(date).format("YYYY-MM-DD");
    setStartDate(new Date(chosenDate));

    Auth.currentAuthenticatedUser().then((user) => {
      let id = user.username;
      const apiName = "ApiGatewayRestApi";
      const path = "/files";
      const myInit = {
        response: true,
        queryStringParameters: { id: id },
      };
      API.get(apiName, path, myInit)
        .then((response) => {
          const progressData = JSON.parse(response.data[4]);
          const dates = [];
          const parsedDates = [];
          progressData.forEach((element) => {
            dates.push(element.competition_date);
          });
          dates.forEach((el) => {
            let parts = el.split("-");
            let mydate = new Date(parts[0], parts[1] - 1, parts[2]);
            parsedDates.push(mydate);
          });
          setHighlightedDates(parsedDates);
          const formattedHighlightedDates = parsedDates.map((el) =>
            moment(el).format("YYYY-MM-DD")
          );
          setFormattedHighlightedDates(formattedHighlightedDates);
        })
        .catch((error) => {
          console.log(error.response);
        });
    });
  }, []);
  //populate on load
  useEffect(() => {
    const complist = [];
    setLoading(true);
    if (formattedHighlightedDates.includes(chosenDateFormatted)) {
      Auth.currentAuthenticatedUser().then((user) => {
        let id = user.username;
        const apiName = "ApiGatewayRestApi";
        const path = "/results";
        const myInit = {
          response: true,
          queryStringParameters: {
            date: chosenDateFormatted,
            id: id,
          },
        };
        API.get(apiName, path, myInit)
          .then((response) => {
            let data = response.data;
            console.log("Received from /results:", data);
            setLoading(false);
            setRankingResults(data[1]);
            setUserResults(data[0]);
            try {
              setStockOutFrequency(
                (data[0].duopoly.stockout.StockoutFrequency * 100).toFixed(2)
              );
              data[0].duopoly.placements.forEach((element) => {
                complist.push(element.CompetitiorName[0]);
              });
              const uniq = [...new Set(complist)];
              setCompetitorList(uniq);
              setErrRate(data[0].duopoly.error_rate.ErrorRate * 100).toFixed(2);
              setAvgRequestTime(
                data[0].duopoly.avg_request_time.AverageRequestTime.toFixed(2)
              );
            } catch {
              console.info("Incomplete Data");
            }

            setResultFiles(flattenArray(data[2]));
          })
          .catch((error) => {
            setUserResults(null);
            setDetailedResults();
            console.log("Error");
          });
      });
    }
  }, [chosenDateFormatted, formattedHighlightedDates]);

  const onDateSelect = (thisdate) => {
    const complist = [];
    const chosenDate = moment(thisdate).format("YYYY-MM-DD");

    setUserResults([]);
    setRankingResults([]);
    setCompetitorList([]);
    setCompetitor("");

    if (formattedHighlightedDates.includes(chosenDate)) {
      setLoading(true);
      Auth.currentAuthenticatedUser().then((user) => {
        let id = user.username;
        const apiName = "ApiGatewayRestApi";
        const path = "/results";
        const myInit = {
          response: true,
          queryStringParameters: {
            date: chosenDate,
            id: id,
          },
        };
        API.get(apiName, path, myInit)
          .then((response) => {
            let data = response.data;
            console.log("Received from /results:", data);
            setLoading(false);
            setRankingResults(data[1]);
            setUserResults(data[0]);
            try {
              setStockOutFrequency(
                (data[0].duopoly.stockout.StockoutFrequency * 100).toFixed(2)
              );
              data[0].duopoly.placements.forEach((element) => {
                complist.push(element.CompetitiorName[0]);
              });
              const uniq = [...new Set(complist)];
              setCompetitorList(uniq);
              setErrRate(data[0].duopoly.error_rate.ErrorRate * 100).toFixed(2);
              setAvgRequestTime(
                data[0].duopoly.avg_request_time.AverageRequestTime.toFixed(2)
              );
            } catch {
              console.info("Incomplete Data");
            }

            setResultFiles(flattenArray(data[2]));
          })
          .catch((error) => {
            setUserResults(null);
            setDetailedResults();
            console.log(error);
          });
      });
    } else {
      setNoData(true);
    }
  };


  const handleChange = (e) => {
    const competitor = [e.target.value][0];
    setCompetitor(competitor);
  };

  return (
    <div className={classes.root}>
      <Nav />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        <Container maxWidth="lg" className={classes.container}>
          {noData ? (
            <Collapse in={noData}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setNoData(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <Typography variant="h6" align="left">
                  No data for the selected date
                </Typography>
              </Alert>
            </Collapse>
          ) : null}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom align="left">
                Your selected date:
              </Typography>
              <DatePicker
                dateFormat="yyyy-MM-dd"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  onDateSelect(date);
                }}
                highlightDates={highlightedDates}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <Link
                to={{
                  pathname: "/results",
                  state: ["data1", "data2"],
                }}
                className={classes.link}
              >
                <Button variant="contained" className={classes.btnBack}>
                  Go back
                </Button>
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <Typography variant="h5" gutterBottom align="left">
                      Duopoly
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={5}>
                        <Typography variant="h6" gutterBottom align="left">
                          Percentage competitions won
                        </Typography>
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          <DuopolyBar
                            data={
                              userResults && userResults.duopoly
                                ? userResults.duopoly
                                : null
                            }
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="h5" gutterBottom align="center">
                          Stockout frequency
                        </Typography>
                        <Typography variant="h6" gutterBottom align="center">
                          {stockOutFrequency}%
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        className={classes.btnContainer}
                      >
                        <div>
                          <div>
                            <Typography variant="h5" gutterBottom align="left">
                              Error rate
                            </Typography>
                            <Typography variant="h6" gutterBottom align="left">
                              {errRate}%
                            </Typography>
                          </div>
                          <div>
                            <Typography variant="h5" gutterBottom align="left">
                              Average request time
                            </Typography>
                            <Typography variant="h6" gutterBottom align="left">
                              {avgRequestTime} seconds
                            </Typography>
                          </div>
                        </div>

                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginTop: "1rem", marginBottom: "1rem" }}
                          onClick={() => {
                            downloadFile(resultFiles, 'duopoly_errors.csv');
                          }}
                        >
                          Download Error txt
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            downloadFile(resultFiles, 'duopoly_feedback.data');
                          }}
                        >
                          Download feedback data
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginTop: "1rem" }}
                          className={classes.btnDetail}
                          onClick={() => {
                            downloadFile(resultFiles, 'duopoly_competition_details.csv');
                          }}
                        >
                          Download detailed results
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <DuoTable data={rankingResults} />
                  )}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant="h6" gutterBottom align="left">
                    Competitor selector:
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom align="left">
                    Select a competitor from the list to see how you ranked
                    against them in each competition.
                  </Typography>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">
                      Select
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={competitor}
                      onChange={handleChange}
                    >
                      {competitorList
                        ? competitorList.map((competitor) => (
                            <MenuItem key={competitor} value={competitor}>
                              {competitor}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <Typography variant="h6" gutterBottom align="left">
                      Ranking for this challenge
                    </Typography>
                    {competitor ? (
                      <DuopolyRanking
                        data={userResults ? userResults : null}
                        competitor={competitor}
                      />
                    ) : (
                      <Typography
                        variant="h6"
                        align="center"
                        style={{ color: "#e5e5e5" }}
                        className={classes.paddedHeading}
                      >
                        select a competitor
                      </Typography>
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}

export default withRouter(Duopoly);
