import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import MoodIcon from "@material-ui/icons/Mood";
import MoodBadIcon from "@material-ui/icons/MoodBad";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: "2rem",
  },
  green: {
    height: 110,
    backgroundColor: "#52DB6B",
    textAlign: "center",
  },
  red: {
    height: 110,
    backgroundColor: "#F65656",
    textAlign: "center",
  },
  neutral: {
    height: 110,
    backgroundColor: "#707070",
    textAlign: "center",
  },
  emoji: {
    color: "#f9f9f9",
    fontSize: "4rem",
  },
  testBoxGood: {
    margin: "0 10px",
    height: "170px",
    marginBottom: "1rem",
    marginTop: "1rem",
    backgroundColor: "#52DB6B",
    color: "white",
  },
  testBoxBad: {
    border: "2px solid #FF4500",
    margin: "0 10px",
    height: "170px",
    marginBottom: "1rem",
    marginTop: "1rem",
  },
  testBoxNull: {
    border: "2px solid gray",
    margin: "0 10px",
    height: "170px",
    marginBottom: "1rem",
    marginTop: "1rem",
  },
  importTxt: {
    display: "flex",
  },
}));

export default function DuopolyBox(props) {
  const classes = useStyles();
  const [errorNumDuopoly, setErrNumDuopoly] = useState([]);
  const [errorLogDuopoly, setErrorLogDuopoly] = useState([]);

  const duopolyResults = props.duopolyResults;

  useEffect(() => {
    if (duopolyResults) {
      //convert error rate to number
      let duoErrRate = duopolyResults.duopoly_error_rate;
      let parsedErr = parseFloat(duoErrRate);
      let multipliedErr = parsedErr * 100;
      //save it to state
      setErrNumDuopoly(multipliedErr);
      //save error log to state
      setErrorLogDuopoly(duopolyResults.duopoly_error_log);
    }
  }, [duopolyResults]);

  const downloadDuopolyTxt = (e) => {
    e.preventDefault();
    window.open(errorLogDuopoly);
  };

  const cardColor = () => {
    if (
      //if import error (err 0 but errLog)
      duopolyResults &&
      duopolyResults.duopoly_avg_time < 0.2 &&
      errorNumDuopoly < 1 &&
      errorNumDuopoly === 0 &&
      errorLogDuopoly
    ) {
      return classes.red;
    }
    //if results are good
    else if (
      duopolyResults &&
      duopolyResults.duopoly_avg_time < 0.2 &&
      errorNumDuopoly < 1
    ) {
      return classes.green;
    } else {
      return classes.red;
    }
  };

  const cardIcon = () => {
    //if import error
    if (
      duopolyResults &&
      duopolyResults.duopoly_avg_time < 0.2 &&
      errorNumDuopoly < 1 &&
      errorNumDuopoly === 0 &&
      errorLogDuopoly
    ) {
      return <MoodBadIcon className={classes.emoji} />;
    } else if (
      duopolyResults &&
      duopolyResults.duopoly_avg_time < 0.2 &&
      errorNumDuopoly < 1
    ) {
      return <MoodIcon className={classes.emoji} />;
    } else {
      return <MoodBadIcon className={classes.emoji} />;
    }
  };

  const cardText = () => {
    //if import error
    if (duopolyResults && errorLogDuopoly && errorNumDuopoly === 0) {
      return "Import error!";
    }
    //slow req time but good error rate
    else if (
      duopolyResults &&
      duopolyResults.duopoly_avg_time >= 0.2 &&
      errorNumDuopoly < 1
    ) {
      return "Slow request time!";
    }
    //good req time bad error rate
    else if (
      duopolyResults &&
      duopolyResults.duopoly_avg_time < 0.2 &&
      errorNumDuopoly >= 1
    ) {
      return "Check your errors!";
    }
    //bad req time + bad err rate
    else if (
      duopolyResults &&
      duopolyResults.duopoly_avg_time >= 0.2 &&
      errorNumDuopoly >= 1
    ) {
      return "Check your errors!";
    }
    //if good
    else {
      return "Great Job!";
    }
  };

  const renderDuopolyCard = () => {
    //check if there are results
    if (duopolyResults && duopolyResults.duopoly_avg_time === "None") {
      //if no results empty grey card
      return (
        <React.Fragment>
          <CardContent className={classes.neutral}>
            <SentimentDissatisfiedIcon className={classes.emoji} />
          </CardContent>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Duopoly
            </Typography>
            <br />
            <br />
            <Typography variant="h5" color="textSecondary" component="p">
              No submission
            </Typography>
          </CardContent>
        </React.Fragment>
      );
    } else if (duopolyResults && duopolyResults.duopoly_avg_time !== "None") {
      //if results show card
      return (
        <React.Fragment>
          {/* card color green or red + emoji*/}
          <CardContent className={cardColor()}>{cardIcon()}</CardContent>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Duopoly
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Avg. request Time:
              {duopolyResults ? duopolyResults.duopoly_avg_time : null}s
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Error rate:
              {duopolyResults ? errorNumDuopoly : null}%
            </Typography>
            {/* if error and error not 0 show link */}
            {errorLogDuopoly && errorNumDuopoly !== 0 ? (
              <Link href="" onClick={downloadDuopolyTxt}>
                Error file
              </Link>
            ) : null}
            {errorLogDuopoly && errorNumDuopoly === 0 ? (
              <div className={classes.importTxt}>
                <Typography variant="body2" color="textSecondary" component="p">
                  Import error:
                </Typography>
                <Link href="" onClick={downloadDuopolyTxt}>
                  Error file
                </Link>
              </div>
            ) : null}
            <Typography variant="h5" color="textSecondary" component="p">
              {cardText()}
            </Typography>
          </CardContent>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <CardContent className={classes.neutral}>
            <SentimentDissatisfiedIcon className={classes.emoji} />
          </CardContent>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Duopoly
            </Typography>
            <br />
            <br />
            <Typography variant="h5" color="textSecondary" component="p">
              No submission
            </Typography>
          </CardContent>
        </React.Fragment>
      );
    }
  };

  return (
    <React.Fragment>
      <Card className={classes.root}>{renderDuopolyCard()}</Card>
    </React.Fragment>
  );
}
