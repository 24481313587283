import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Nav from "../components/MainNav";
import { Container, Grid, Paper, Typography, Box } from "@material-ui/core";
import blackboard from "../assets/blackboard.gif";
import upload from "../assets/upload.gif";
import slackLogo from "../assets/slack-icon.png";
import { useHistory } from "react-router-dom";
import ProgressBarDuo from "../components/ProgressBarDuo";
import ProgressBarOli from "../components/ProgressBarOli";
import ProgressBarDyn from "../components/ProgressBarDyn";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paddedHeading: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  animatedIcon: {
    height: "80px",
    width: "auto",
  },
  paperBar: {
    padding: theme.spacing(7),
    textAlign: "center",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  lastHeading: {
    paddingBottom: theme.spacing(4),
  },
  alias: {
    backgroundColor: "#FBF033",
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const history = useHistory();
  const [firstName, setFirstName] = useState("");
  const [elevation, setElevation] = useState(1);
  const [alias, setAlias] = useState("");
  const [duoProgress, setDuoProgress] = useState("");
  //const [oliProgress, setOliProgress] = useState("");
  //const [dynProgress, setDynProgress] = useState("");

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      setFirstName(user.attributes.given_name);
      let id = user.username;
      const apiName = "ApiGatewayRestApi";
      const path = "/files";
      const myInit = {
        response: true,
        queryStringParameters: { id: id },
      };
      API.get(apiName, path, myInit)
        .then((response) => {
          setAlias(response.data[2].alias);
          const progressData = JSON.parse(response.data[4]);
          const dates = [];
          progressData.forEach((element) => {
            dates.push(element.competition_date);
          });

          let moments = dates.map((d) => moment(d));
          const maxArrDate = moment.max(moments);
          const maxdate = maxArrDate._i;

          const result = progressData.filter((obj) => {
            return obj.competition_date === maxdate;
          });

          const duopolyProgress = result[0].duopoly_progress * 100;
          //const oligopolyProgress = result[0].oligopoly_progress * 100;
          const dynamicProgress = result[0].dynamic_progress * 100;
          if (
            duopolyProgress > 0
            // && oligopolyProgress > 0 &&
            //dynamicProgress > 0
          ) {
            setDuoProgress(duopolyProgress);
            //setOliProgress(oligopolyProgress);
            // setDynProgress(dynamicProgress);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }, []);

  const onMouseOver = () => {
    setElevation(12);
  };
  const onMouseOut = () => {
    setElevation(1);
  };

  const handleClickFiles = () => {
    history.push("/files");
  };
  const handleClickResults = () => {
    history.push("/results");
  };

  const linkToSlack = () => {
    window.open(
      "https://join.slack.com/t/dpc2021/shared_invite/zt-sq2tv6et-X5p5FLZtG7zyRCoNMNYB_A",
      "_blank"
    );
  };
  return (
    <div className={classes.root}>
      <Nav />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Typography variant="h4" className={classes.paddedHeading}>
            Welcome, {firstName}.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper} style={{ height: "100%" }}>
                    <Typography
                      variant="h6"
                      className={classes.paddedHeading}
                      align="left"
                    >
                      Your anonymized username is {"\u00A0"}
                      <Box
                        display="inline"
                        fontWeight="fontWeightBold"
                        className={classes.alias}
                      >
                        {alias}
                      </Box>
                      <br />
                      Your competition performance will show up on leaderboard
                      rankings under this anonymized username.
                    </Typography>

                    <Typography
                      variant="h6"
                      align="left"
                      className={classes.lastHeading}
                    >
                      <p>
                        The training phase of this year's edition of the DPC has
                        started. You can now upload and test your algorithms.
                        The simulation is currently running on three days and
                        {" "}
                        <strong>
                          starts on Monday, Wednesday and Saturday, at 0:30am
                          UTC.
                        </strong>{" "}
                        Make sure you upload your latest code before that time.
                        Note that the simulation duration can take 12-15 hours.
                      </p>

                      <p>
                        This year, we will again only support the{" "}
                        <strong>Duopoly pricing scenario </strong>(challenge)We
                        do this to bundle the simulation resources and {" "}
                        <strong>
                          better support reinforcement learning algorithms.
                        </strong>
                      </p>
                      
                      <p>
                        We hope you enjoy the competition.{" "}
                        <strong>
                          Final submission date is the 31st of December.
                        </strong>
                      </p>
                    </Typography>
                    {/* DPC 2021 is over and the winners have been contacted. You
                      can still test your algorithm by uploading it and see
                      whether it fulfills the criteria to participate in the
                      coming-up DPC 2022 (to be announced at a later date). */}

                    {/* 
                    -The training phase is now live with simulations every
                      Tuesday and Friday! Daily simulations will resume in
                      November 2021.
                    -Upload your algorithms in the File Manager section and
                      check your results in the Results section.
                      -The training phase is over and we are now running
                      extensive evaluations to determine the final winners of
                      DPC 2021. The winners are likely to be announced in late
                      January / early February. */}
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paperBar}>
                    <Typography variant="h5" gutterBottom align="left">
                      Simulations progress
                    </Typography>
                    <Typography variant="h6" gutterBottom align="left">
                      Duopoly
                    </Typography>
                    <ProgressBarDuo progressValue={duoProgress} />
                    {/* <Typography variant="h6" gutterBottom align="left">
                      Oligopoly
                    </Typography>
                    <ProgressBarOli progressValue={oliProgress} />
                    <Typography variant="h6" gutterBottom align="left">
                      Dynamic
                    </Typography>
                    <ProgressBarDyn progressValue={dynProgress} /> */}
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" className={classes.paddedHeading}>
                    Where to next?
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper
                    className={classes.paper}
                    elevation={elevation}
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                    onClick={handleClickFiles}
                    style={{ cursor: "pointer" }}
                  >
                    <Typography variant="h6" gutterBottom align="left">
                      File Manager
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="row"
                      bgcolor="background.paper"
                    >
                      <Box p={1}>
                        <img
                          src={upload}
                          alt=""
                          className={classes.animatedIcon}
                        />
                      </Box>
                      <Box p={1}>
                        <Typography variant="body2" gutterBottom align="left">
                          Upload your files and see your previous submissions
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper
                    className={classes.paper}
                    elevation={elevation}
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                    onClick={handleClickResults}
                    style={{ cursor: "pointer" }}
                  >
                    <Typography variant="h6" gutterBottom align="left">
                      Results
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="row"
                      bgcolor="background.paper"
                    >
                      <Box p={1}>
                        <img
                          src={blackboard}
                          alt=""
                          className={classes.animatedIcon}
                        />
                      </Box>
                      <Box p={1}>
                        <Typography variant="body2" gutterBottom align="left">
                          See the latest developments and rankings
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper
                    className={classes.paper}
                    elevation={elevation}
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                    onClick={linkToSlack}
                    style={{ cursor: "pointer", height: " 100%" }}
                  >
                    <Typography variant="h6" gutterBottom align="left">
                      Slack channel
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="row"
                      bgcolor="background.paper"
                    >
                      <Box p={1}>
                        <img
                          src={slackLogo}
                          alt=""
                          style={{ height: "50px" }}
                        />
                      </Box>
                      <Box p={1}>
                        <Typography variant="body2" gutterBottom align="left">
                          Join our Slack channel to stay up to date
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
};

export default Dashboard;
