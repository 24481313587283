import React, { useState, useEffect } from "react";
import Amplify, { API, Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Nav from "../components/MainNav";
import {
  Container,
  Button,
  Paper,
  Typography,
  Link,
  Grid,
  Tooltip,
  Collapse,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { DropzoneArea } from "material-ui-dropzone";
import awsconfig from "../aws-exports";
import { useDebounce } from "use-debounce";
import ResultBoxes from "../components/ResultBoxes";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CircularLoadingIcon from "../components/CircularLoadingIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  btnUpload: {
    backgroundColor: "#BC343E",
    color: "white",
    marginTop: "2rem",
    display: "block",
    "&:hover": {
      backgroundColor: "#BC343E",
    },
    "&:disabled": {
      backgroundColor: "#e5e5e5",
    },
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: "24px",
    textAlign: "left",
  },
  // testContainer: {
  //   marginTop: "2rem",
  // },
  testWrapper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
  },
  circularProgress: {
    alignSelf: "center",
    marginTop: "2rem",
  },
  statusWrapper: {
    display: "flex",
  },
  testContainer: {
    marginTop: "2rem",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
  },
  testBox: {
    border: "2px solid #52DB6B",
    margin: "0 10px",
  },
  testBoxBad: {
    border: "2px solid #FF4500",
    margin: "0 10px",
  },
  testBoxNull: {
    border: "2px solid gray",
    margin: "0 10px",
  },
  dropzone: {
    height: "50%",
  },
}));

export default function FileManager() {
  const classes = useStyles();

  const [latestFiles, setLatestFiles] = useState([]);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [noUpload, setNoUpload] = useState([]);
  const [uploaded, setUploaded] = useState(false);
  const [invalidateFile, setInvalidateFile] = useState(true);

  const [fileKeys, setFileKeys] = useState([]);
  const [userId, setUserId] = useState([]);
  const [key, setKey] = useState(0);
  const [debounceKey] = useDebounce(key, 1000);
  const [duopolyUploaded, setDuopolyUploaded] = useState(false);
  const [oligopolyUploaded, setOligopolyUploaded] = useState(false);
  const [dynamicUploaded, setDynamicUploaded] = useState(false);
  const [testResults, setTestResults] = useState({});
  const [errTesting, setErrTesting] = useState("");
  const [open, setOpen] = useState(false);
  const [fetchingData, setFetchingData] = useState(true);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      let id = user.username;
      setUserId(id);
      const apiName = "ApiGatewayRestApi";
      const path = "/files";
      const myInit = {
        response: true,
        queryStringParameters: {
          id: id,
        },
      };

      setDuopolyUploaded(false);
      // setOligopolyUploaded(false);
      // setDynamicUploaded(false);

      API.get(apiName, path, myInit)
        .then((response) => {
          console.log(response.data[3]);
          //if no error message
          if (typeof response.data[0] != "string") {
            //show latest files
            setFileKeys(response.data[0]);
            setLatestFiles(response.data[1]);
            //store test results
            setTestResults(response.data[3]);
          } else {
            setNoUpload(response.data[0]);
          }
          //check if uploads contain duopoly.py/oligopoly.py/dynamic.py
          const sorted = response.data[1].map((v) => v.toLowerCase());
          if (
            sorted.some(function(v) {
              return v.indexOf("duopoly.py") >= 0;
            })
          ) {
            setDuopolyUploaded(true);
          }
          //   if (
          //     sorted.some(function (v) {
          //       return v.indexOf("oligopoly.py") >= 0;
          //     })
          //   ) {
          //     setOligopolyUploaded(true);
          //   }
          //   if (
          //     sorted.some(function (v) {
          //       return v.indexOf("dynamic.py") >= 0;
          //     })
          //   ) {
          //     setDynamicUploaded(true);
          //   }
          //
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => {
          setFetchingData(false);
        });
    });
  }, [uploaded]);

  const post = (bodyArr) => {
    const apiName = "ApiGatewayRestApi";
    const path = "/upload";
    const myInit = {
      body: bodyArr,
    };
    setFetchingData(true);
    API.post(apiName, path, myInit)
      .then((response) => {
        if (response) {
          setKey((prevKey) => prevKey + 1);
          setUploaded(true);
          setselectedFiles([]);
        }
      })
      .catch((error) => {
        if (error.response) {
          setErrTesting(error.response.data);
          setOpen(true);
        }
      })
      .finally(() => {
        setFetchingData(false);
      });
  };

  const onFileChange = (files) => {
    let arr = [];
    files.forEach((item) => {
      let duopoly;
      // let oligopoly;
      // let dynamic;
      if (item.name.toLowerCase() === "duopoly.py") {
        duopoly = 0;
      } else {
        duopoly = null;
      }

      // if (item.name.toLowerCase() === "oligopoly.py") {
      //   oligopoly = 0;
      // } else {
      //   oligopoly = null;
      // }

      // if (item.name.toLowerCase() === "dynamic.py") {
      //   dynamic = 0;
      // } else {
      //   dynamic = null;
      // }
      let reader = new FileReader();
      reader.readAsDataURL(item);
      reader.onload = () => {
        var Base64 = reader.result;
        let jsonObj = {
          userId: userId,
          encoded: Base64,
          type: item.type,
          path: item.path,
          name: item.name,
          duopoly: duopoly,
          oligopoly: null, //not used in the current competition else set oligopoly: oligopoly
          dynamic: null, //not used in the current competition else set oligopoly: oligopoly
        };
        arr.push(jsonObj);
      };
      setselectedFiles(arr);
      setInvalidateFile(false);
    });
  };

  const sendToPost = () => {
    let duopoly = selectedFiles.filter((item) => item.name === "duopoly.py");
    if (duopoly.length == 0) {
      alert("Missing the required file 'duopoly.py' in the submission.");
      return;
    } else if (duopoly.length > 1) {
      alert(
        "You have uploaded more than one 'duopoly.py' file. Please remove the duplicates."
      );
      return;
    }
    post(selectedFiles);
  };

  const downloadFile = (e) => {
    const searchTerm = e.target.outerText;
    const search = (text) => fileKeys.filter(({ Key }) => Key.includes(text));
    const match = search(searchTerm);
    const apiName = "ApiGatewayRestApi";
    const path = "/files/downloadFile/";
    const myInit = {
      response: true,
      queryStringParameters: {
        key: match[0].Key,
      },
    };
    API.get(apiName, path, myInit)
      .then((response) => {
        window.open(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <div className={classes.root}>
      <Nav />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {fetchingData && <CircularLoadingIcon text={"Accessing data..."} />}
        <Container maxWidth="lg" className={classes.container}>
          <Paper className={classes.paper}>
            <div className={classes.uploadedWrapper}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={5}>
                  <Typography variant="h5">
                    Your latest uploads: <br />
                    {latestFiles.map((txt) => (
                      <p key={txt}>
                        <Link onClick={downloadFile}>{txt}</Link>
                      </p>
                    ))}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} style={{ marginTop: "3rem" }}>
                  {duopolyUploaded ? (
                    <div className={classes.statusWrapper}>
                      <CheckCircleIcon
                        style={{ color: "#32CD32" }}
                        fontSize="large"
                      />
                      <Typography variant="h5">Duopoly</Typography>
                    </div>
                  ) : (
                    <div className={classes.statusWrapper}>
                      <RemoveCircleIcon
                        style={{ color: "red" }}
                        fontSize="large"
                      />
                      <Tooltip
                        title="You didn't upload a duopoly file!"
                        placement="top"
                      >
                        <Typography variant="h5">Duopoly</Typography>
                      </Tooltip>
                    </div>
                  )}
                  {/* {oligopolyUploaded ? (
                    <div className={classes.statusWrapper}>
                      <CheckCircleIcon
                        style={{ color: "#32CD32" }}
                        fontSize="large"
                      />
                      <Typography variant="h5">Oligopoly</Typography>
                    </div>
                  ) : (
                    <div className={classes.statusWrapper}>
                      <RemoveCircleIcon
                        style={{ color: "red" }}
                        fontSize="large"
                      />
                      <Tooltip
                        title="You didn't upload any Oligopoly file!"
                        placement="top">
                        <Typography variant="h5">Oligopoly</Typography>
                      </Tooltip>
                    </div>
                  )}
                  {dynamicUploaded ? (
                    <div className={classes.statusWrapper}>
                      <CheckCircleIcon
                        style={{ color: "#52DB6B" }}
                        fontSize="large"
                      />
                      <Typography variant="h5">Dynamic</Typography>
                    </div>
                  ) : (
                    <div className={classes.statusWrapper}>
                      <RemoveCircleIcon
                        style={{ color: "red" }}
                        fontSize="large"
                      />
                      <Tooltip
                        title="You didn't upload a Dynamic file!"
                        placement="top">
                        <Typography variant="h5">Dynamic</Typography>
                      </Tooltip>
                    </div>
                  )} */}
                </Grid>
              </Grid>
            </div>
          </Paper>
          {uploaded ? (
            <Collapse in={uploaded}>
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setUploaded(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <Typography variant="h5" align="center">
                  Upload successful! Come back in 15 mins for test results
                </Typography>
              </Alert>
            </Collapse>
          ) : null}
          <br />
          {errTesting && (
            <Collapse in={open}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {errTesting}
              </Alert>
            </Collapse>
          )}
          <br />
          <DropzoneArea
            onChange={onFileChange}
            key={debounceKey}
            filesLimit={30}
            showPreviews={true}
            showPreviewsInDropzone={false}
            useChipsForPreview
            disabled
            previewGridProps={{
              container: { spacing: 1, direction: "row" },
            }}
            previewChipProps={{ classes: { root: classes.previewChip } }}
            previewText="Selected files"
            className={classes.dropzone}
          />
          <div>
            <b>
              Please add here all files you want to add to your competition
              submission for the next simulation run. Note that your submission
              needs to contain one file with the exact naming "duopoly.py",
              which contains the pricing function p(.). After selecting the
              submission files, you need to press "UPLOAD!" in order to submit
              the files to the DPC cloud.
            </b>
          </div>
          <Button
            disabled={invalidateFile}
            size="large"
            fullWidth={true}
            onClick={sendToPost}
            className={classes.btnUpload}
          >
            Upload!
          </Button>
          <ResultBoxes results={testResults} />
        </Container>
      </main>
    </div>
  );
}
