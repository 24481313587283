const flattenArray = (arr) => {
  let result = {};
  try {
    arr.map((obj, idx) => {
      for (const [key, val] of Object.entries(obj)) {
        result[key] = val;
      }
    });
  } catch (err) {
    console.log(err);
  }

  return result;
};

const downloadFile = (obj, key) => {
  let url = obj[key];
  if (url) {
    var link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", key);
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".
  } else {
    alert("The requested file does not exist");
    return false;
  }
};

export { flattenArray, downloadFile };
