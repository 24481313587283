import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Text,
} from "recharts";

export default function OligopolyRanking(props) {
  const [data, setData] = useState("");
  const [ticks, setTicks] = useState("");
  const [interval, setTickInterval] = useState("");
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);

  useEffect(() => {
    if (props && props.data && props.data.oligopoly && props.competitor) {
      const compList = props.competitor.split(",").map(function (value) {
        return value.trim();
      });

      const propsArr = props.data.oligopoly.placements;
      const filteredArr = propsArr.filter(
        (el) => JSON.stringify(el.CompetitiorName) === JSON.stringify(compList)
      );
      const arr = [];
      const values = [];
      if (filteredArr.length < 20) {
        setTickInterval(0);
      } else if (filteredArr.length < 20) {
        setTickInterval(4);
      }

      filteredArr.forEach((element, index) => {
        var elem = {};
        elem.competition = `competition ${index}`;
        elem.rank = element.Placement;
        arr.push(elem);
        values.push(element.Placement);
        element.CompetitiorRankings.forEach((el) => {
          values.push(el);
        });
      });
      setData(arr);

      const uniq = [...new Set(values)];
      const min = Math.min(...values);
      const max = Math.max(...values);
      const minYAxis = Math.floor(min - 1);
      const maxYAxis = Math.ceil(max + 1);
      setMinValue(minYAxis);
      setMaxValue(maxYAxis);
      setTicks(uniq.sort());
    }
  }, [props]);

  const CustomizedLabelB = () => {
    return (
      <Text
        x={0}
        y={0}
        dx={-150}
        dy={40}
        textAnchor="start"
        width={180}
        transform="rotate(-90)">
        Position
      </Text>
    );
  };

  return (
    <div>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          width={500}
          height={300}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="competition"
            label={{
              value: "Simulation",
              position: "insideBottom",
              dy: 10,
            }}
            scale="auto"
            tickSize={10}
            interval={interval}
            allowDecimals={false}
            angle={-15}
            textAnchor="end"
            height={60}
          />
          <YAxis
            reversed="true"
            type="number"
            domain={[minValue, maxValue]}
            allowDecimals={false}
            scale="auto"
            tickSize={0}
            interval={0}
            ticks={ticks ? ticks : null}
            label={<CustomizedLabelB />}
          />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="rank"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
            dot={{ stroke: "red", strokeWidth: 3 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
