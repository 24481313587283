import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Text,
} from "recharts";

export default function DuopolyRanking(props) {
  const [data, setData] = useState("");

  useEffect(() => {
    if (props && props.data && props.data.duopoly) {
      const propsArr = props.data.duopoly.placements;
      const filteredArr = propsArr.filter(
        (el) => el.CompetitiorName[0] === props.competitor
      );
      const arr = [];
      filteredArr.forEach((element, index) => {
        var elem = {};
        elem.competition = `competition ${index}`;
        elem.rank = element.Placement;
        arr.push(elem);
      });

      setData(arr);
    }
  }, [props]);

  const CustomizedLabelB = () => {
    return (
      <Text
        x={0}
        y={0}
        dx={-150}
        dy={40}
        textAnchor="start"
        width={180}
        transform="rotate(-90)">
        Position
      </Text>
    );
  };

  return (
    <div>
      <ResponsiveContainer
        width="90%"
        height="100%"
        style={{ marginBottom: "2rem" }}>
        <LineChart
          data={data}
          width={300}
          height={300}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="competition"
            label={{
              value: "Simulation",
              position: "insideBottom",
              dy: 20,
            }}
            scale="auto"
            //tickSize={1}
            interval={4}
            tick={{ dy: 10, dx: 5 }}
            angle={-15}
            textAnchor="end"
            height={60}
          />
          <YAxis
            reversed="true"
            type="number"
            domain={["dataMin", "dataMax"]}
            allowDecimals={false}
            scale="auto"
            tickSize={1}
            interval={0}
            label={<CustomizedLabelB />}
            ticks={[0, 1, 2, 3, 4]}
          />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="rank"
            //stroke="#8884d8"
            activeDot={{ r: 8 }}
            dot={{ stroke: "red", strokeWidth: 3 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
