import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Nav from "../components/MainNav";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import RankingsSmallTable from "../components/RankingsSmallTable";
import OligopolyBar from "../components/OligopolyBar";
import DynamicBar from "../components/DynamicBar";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DuopolyBar from "../components/DuopolyBar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  logo: {
    width: "100px",
    height: "auto",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  appbar: {
    position: "relative",
    top: "-10px",
    width: "97%",
    margin: "0 auto",
  },
  appbarTitle: { color: "#ffff" },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paddedHeading: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  animatedIcon: {
    height: "80px",
    width: "auto",
  },
  barHeading: {
    paddingBottom: theme.spacing(4),
    marginTop: "3rem",
  },
  paperBar: {
    padding: theme.spacing(7),
    textAlign: "center",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  btnContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  link: {
    textDecoration: "none",
  },
}));

export default function Results(props) {
  const classes = useStyles();
  const [highlightedDates, setHighlightedDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [userResults, setUserResults] = useState([]);
  const [rankingResults, setRankingResults] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [duopolyStockOutFrequency, setDuopolyStockOutFrequency] = useState(0);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      let id = user.username;
      const apiName = "ApiGatewayRestApi";
      const path = "/files";
      const myInit = {
        response: true,
        queryStringParameters: { id: id },
      };
      API.get(apiName, path, myInit)
        .then((response) => {
          const progressData = JSON.parse(response.data[4]);
          const dates = [];
          const parsedDates = [];
          progressData.forEach((element) => {
            dates.push(element.competition_date);
          });
          dates.forEach((el) => {
            let parts = el.split("-");
            let mydate = new Date(parts[0], parts[1] - 1, parts[2]);
            parsedDates.push(mydate);
          });
          setHighlightedDates(parsedDates);
        })
        .catch((error) => {
          console.log(error.response);
        });

      const resultsPath = "/results";
      const params = {
        response: true,
        queryStringParameters: {
          date: moment().format("YYYY-MM-DD"),
          id: id,
        },
      };

      API.get(apiName, resultsPath, params)
        .then((response) => {
          setLoading(false);
          setData(response.data);
          setUserResults(response.data[0]);
          localStorage.setItem("selectedDate", JSON.stringify(startDate));
          try{
            setDuopolyStockOutFrequency(
              (response.data[0].duopoly.stockout.StockoutFrequency * 100).toFixed(
                2
              )
            );
          }catch{
            console.log("Could not set stock out frequency")
          }
          setRankingResults(response.data[1]);
        })
        .catch((error) => {
          setUserResults(null);
          console.log("error", error.response);
        });
    });
  }, []);

  const onDateSelect = (thisdate) => {
    localStorage.setItem("selectedDate", JSON.stringify(thisdate));
    const formattedHighlightedDates = highlightedDates.map((el) =>
      moment(el).format("YYYY-MM-DD")
    );
    const chosenDate = moment(thisdate).format("YYYY-MM-DD");
    if (formattedHighlightedDates.includes(chosenDate)) {
      setLoading(true);
      setData([]);
      setUserResults([]);
      setDuopolyStockOutFrequency([]);
      setRankingResults([]);
      Auth.currentAuthenticatedUser().then((user) => {
        let id = user.username;
        const apiName = "ApiGatewayRestApi";
        const path = "/results";
        const myInit = {
          response: true,
          queryStringParameters: {
            date: chosenDate,
            id: id,
          },
        };

        API.get(apiName, path, myInit)
          .then((response) => {
            setLoading(false);
            setData(response.data);
            setUserResults(response.data[0]);
            try{
              setDuopolyStockOutFrequency(
                (response.data[0].duopoly.stockout.StockoutFrequency * 100).toFixed(
                  2
                )
              );
            }catch{
              console.log("Could not set stock out frequency")
            }
            setRankingResults(response.data[1]);
          })
          .catch((error) => {
            setUserResults(null);
            console.log("error", error.response);
          });
      });
    }
  };

  return (
    <div className={classes.root}>
      <Nav />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom align="left">
                Select a date to view your results:
              </Typography>
              <Typography variant="subtitle2" gutterBottom align="left">
                Note: in case you have not participated on a given day, your
                results for this day will be empty
              </Typography>
              <DatePicker
                dateFormat="yyyy-MM-dd"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  onDateSelect(date);
                }}
                highlightDates={highlightedDates}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <Grid container spacing={2}>
                {/*------------------------------------ DUOPOLY PAPER */}
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <Typography variant="h5" gutterBottom align="left">
                      Duopoly
                    </Typography>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={5}>
                          <Typography variant="h6" gutterBottom align="left">
                            Distribution of placements
                          </Typography>

                          {userResults ? (
                            <DuopolyBar data={userResults.duopoly} />
                          ) : (
                            <Typography
                              variant="h6"
                              align="center"
                              style={{ color: "#e5e5e5" }}
                              className={classes.paddedHeading}>
                              No data for the selected date
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Typography variant="h6" gutterBottom align="center">
                            Stockout frequency
                          </Typography>

                          {duopolyStockOutFrequency ? (
                            <Typography
                              variant="h4"
                              gutterBottom
                              align="center">
                              {duopolyStockOutFrequency} %
                            </Typography>
                          ) : (
                            <Typography
                              variant="h6"
                              align="center"
                              style={{ color: "#e5e5e5" }}
                              className={classes.paddedHeading}>
                              No data for the selected date
                            </Typography>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          className={classes.btnContainer}>
                          <div>
                            <Typography
                              variant="h6"
                              gutterBottom
                              align="center">
                              Error rate
                            </Typography>

                            {userResults && userResults.duopoly ? (
                              <Typography
                                variant="h4"
                                gutterBottom
                                align="center">
                                {(userResults.duopoly.error_rate.ErrorRate * 100).toFixed(2)}
                                %
                              </Typography>
                            ) : (
                              <Typography
                                variant="h6"
                                align="center"
                                style={{ color: "#e5e5e5" }}
                                className={classes.paddedHeading}>
                                No data for the selected date
                              </Typography>
                            )}
                          </div>
                          <Link
                            to={{
                              pathname: data ? "/duopoly" : "/results",
                            }}
                            target="_blank"
                            className={classes.link}>
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.btnDetail}>
                              Detailed view
                            </Button>
                          </Link>
                        </Grid>
                      </Grid>
                    )}
                  </Paper>
                </Grid>
                {/* ------------------------------------------OLIGOPOLY PAPER */}

                {/* <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <Typography variant="h5" gutterBottom align="left">
                      Oligopoly
                    </Typography>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <Grid
                        container
                        spacing={2}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                        <Grid item xs={12} sm={8}>
                          <Typography variant="h6" gutterBottom align="left">
                            Distribution of placements
                          </Typography>

                          {userResults ? (
                            <OligopolyBar data={userResults.oligopoly} />
                          ) : (
                            <Typography
                              variant="h6"
                              align="center"
                              style={{ color: "#e5e5e5" }}
                              className={classes.paddedHeading}>
                              No data for the selected date
                            </Typography>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          className={classes.btnContainer}>
                          <div>
                            <Typography
                              variant="h6"
                              gutterBottom
                              align="center">
                              Error rate
                            </Typography>
                            {loading ? <CircularProgress /> : null}
                            {userResults && userResults.oligopoly ? (
                              <Typography
                                variant="h4"
                                gutterBottom
                                align="center">
                                {(userResults.duopoly.error_rate.ErrorRate * 100).toFixed(2)}
                                %
                              </Typography>
                            ) : (
                              <Typography
                                variant="h6"
                                align="center"
                                style={{ color: "#e5e5e5" }}
                                className={classes.paddedHeading}>
                                No data for the selected date
                              </Typography>
                            )}
                          </div>
                          <Link
                            to={{
                              pathname: data && "/oligopoly",
                            }}
                            target="_blank"
                            className={classes.link}>
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.btnDetail}>
                              Detailed view
                            </Button>
                          </Link>
                        </Grid>
                      </Grid>
                    )}
                  </Paper>
                </Grid> */}
                {/* ------------------------------------------DYNAMIC PAPER */}
                {/* <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <Typography variant="h5" gutterBottom align="left">
                      Dynamic
                    </Typography>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <Grid
                        container
                        spacing={2}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                        <Grid item xs={12} sm={8}>
                          <Typography variant="h6" gutterBottom align="left">
                            Distribution of placements
                          </Typography>

                          {userResults ? (
                            <DynamicBar data={userResults.dynamic} />
                          ) : (
                            <Typography
                              variant="h6"
                              align="center"
                              style={{ color: "#e5e5e5" }}
                              className={classes.paddedHeading}>
                              No data for the selected date
                            </Typography>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          className={classes.btnContainer}>
                          <div>
                            <Typography
                              variant="h6"
                              gutterBottom
                              align="center">
                              Error rate
                            </Typography>
                            {loading ? <CircularProgress /> : null}
                            {userResults && userResults.dynamic ? (
                              <Typography
                                variant="h4"
                                gutterBottom
                                align="center">
                                {(userResults.duopoly.error_rate.ErrorRate * 100).toFixed(2)}
                                %
                              </Typography>
                            ) : (
                              <Typography
                                variant="h6"
                                align="center"
                                style={{ color: "#e5e5e5" }}
                                className={classes.paddedHeading}>
                                No data for the selected date
                              </Typography>
                            )}
                          </div>
                          <Link
                            to={{
                              pathname: data ? "/dynamic" : "/results",
                            }}
                            target="_blank"
                            className={classes.link}>
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.btnDetail}>
                              Detailed view
                            </Button>
                          </Link>
                        </Grid>
                      </Grid>
                    )}
                  </Paper>
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <RankingsSmallTable data={rankingResults} />
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}
