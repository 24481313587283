import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  withStyles,
  Grid,
  SwipeableDrawer,
  Button,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as Logo } from "../assets/DPC-logo.svg";

const styleSheet = {
  list: {
    width: 200,
  },
  padding: {
    paddingRight: 30,
    cursor: "pointer",
  },

  sideBarIcon: {
    padding: 0,
    color: "black",
    cursor: "pointer",
  },
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: "transparent",
    color: "#141446",
    boxShadow: "none",
    position: "fixed",
  },
  logo: {
    width: "90px",
    height: "auto",
  },
  toolBar: {
    display: "flex",
  },
  firstLink: {
    marginLeft: "auto",
    padding: "0 20px",
  },
  navItem: {
    padding: "0 20px",
  },
};

class LoggedOutNav extends Component {
  constructor(props) {
    super(props);
    this.state = { drawerActivate: false, drawer: false };
    this.createDrawer = this.createDrawer.bind(this);
    this.destroyDrawer = this.destroyDrawer.bind(this);
  }

  componentDidMount() {
    if (window.innerWidth <= 600) {
      this.setState({ drawerActivate: true });
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth <= 600) {
        this.setState({ drawerActivate: true });
      } else {
        this.setState({ drawerActivate: false });
      }
    });
  }

  //Small Screens
  createDrawer() {
    return (
      <div>
        <AppBar
          className={this.props.appBar}
          style={{ backgroundColor: "transparent", boxShadow: "none" }}>
          <Toolbar className={this.props.toolBar}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center">
              <MenuIcon
                className={this.props.classes.sideBarIcon}
                onClick={() => {
                  this.setState({ drawer: true });
                }}
              />
              <IconButton
                edge="start"
                style={{ flexGrow: 1 }}
                color="inherit"
                aria-label="menu">
                <Logo style={{ width: "90px", height: "auto" }} />
              </IconButton>
              <Typography color="inherit" variant="headline"></Typography>
            </Grid>
          </Toolbar>
        </AppBar>

        <SwipeableDrawer
          open={this.state.drawer}
          onClose={() => {
            this.setState({ drawer: false });
          }}
          onOpen={() => {
            this.setState({ drawer: true });
          }}>
          <div
            tabIndex={0}
            role="button"
            onClick={() => {
              this.setState({ drawer: false });
            }}
            onKeyDown={() => {
              this.setState({ drawer: false });
            }}>
            <List className={this.props.classes.list}>
              <ListItem
                key={1}
                button
                divider
                component="a"
                target="_blank"
                href="https://dynamic-pricing-competition.com/challenges/">
                {" "}
                Challenges{" "}
              </ListItem>
              <ListItem
                key={2}
                button
                divider
                component="a"
                target="_blank"
                href="https://dynamic-pricing-competition.com/getting-started/">
                {" "}
                Getting started{" "}
              </ListItem>
              <ListItem
                key={3}
                button
                divider
                component="a"
                target="_blank"
                href="https://dynamic-pricing-competition.com/timeline/">
                {" "}
                Timeline{" "}
              </ListItem>
              <ListItem
                key={4}
                button
                divider
                component="a"
                target="_blank"
                href="https://dynamic-pricing-competition.com/winners/">
                {" "}
                Winners{" "}
              </ListItem>
            </List>
          </div>
        </SwipeableDrawer>
      </div>
    );
  }

  //Larger Screens
  destroyDrawer() {
    const { classes } = this.props;
    return (
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <IconButton edge="start" color="inherit" aria-label="menu">
            <Logo
              style={{ width: "90px", height: "auto", marginTop: "1rem" }}
            />
          </IconButton>
          <Button
            color="inherit"
            target="_blank"
            href="https://dynamic-pricing-competition.com/challenge-duopoly/"
            className={classes.firstLink}>
            Challenge
          </Button>
          <Button
            color="inherit"
            target="_blank"
            href="https://dynamic-pricing-competition.com/getting-started/"
            className={classes.navItem}>
            Getting started
          </Button>
          <Button
            color="inherit"
            target="_blank"
            href="https://dynamic-pricing-competition.com/timeline/"
            className={classes.navItem}>
            Timeline
          </Button>
          <Button
            color="inherit"
            target="_blank"
            href="https://dynamic-pricing-competition.com/winners/"
            className={classes.navItem}>
            Winners
          </Button>
        </Toolbar>
      </AppBar>
    );
  }

  render() {
    return (
      <div>
        {this.state.drawerActivate ? this.createDrawer() : this.destroyDrawer()}
      </div>
    );
  }
}

LoggedOutNav.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styleSheet)(LoggedOutNav);
