import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { forwardRef } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function DuoTable(props) {
  const [data, setData] = useState([]);
  const [minRevenue, setMinRevenue] = useState(null);
  const [maxRevenue, setMaxRevenue] = useState(null);
  const [midRevenue, setMidRevenue] = useState(null);
  const [quarterRevenue, setQuarterRevenue] = useState(null);
  const [threeQuartRevenue, setThreeQuartRevenue] = useState(null);

  useEffect(() => {
    const totRevs = [];
    if (props.data) {
      if (props.data.duopoly) {
        const duoProps = props.data.duopoly;
        const formattedDuoData = duoProps.map((item) => ({
          ...item,
          AverageRanking: Number(item.AverageRanking.toFixed(2)),
        }));
        setData(formattedDuoData);
        formattedDuoData.forEach((element) => {
          totRevs.push(element.total_revenue);
        });
        const min = Math.min(...totRevs);
        const max = Math.max(...totRevs);
        setMinRevenue(min);
        setMaxRevenue(max);
        setMidRevenue((max + min) / 2);
        setQuarterRevenue((max + min) / 4);
        setThreeQuartRevenue(((max + min) / 4) * 3);
      }
    }
  }, [props.data]);

  return (
    <div>
      <MaterialTable
        icons={tableIcons}
        options={{
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [10, 20, 50],
          overflowX: 'auto' // Enable horizontal scrolling
        }}
        title="Ranking"
        columns={[
          {
            title: "Overall Ranking",
            field: "Ranking",
            type: "numeric",
          },
          { title: "Name", field: "Name" },
          {
            title: "Total Revenue",
            field: "total_revenue",
            type: "currency",
            currencySetting: {
              // locale: "hu",
              currencyCode: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            },
            cellStyle: (e, rowData) => {
              if (rowData.total_revenue === maxRevenue) {
                return { backgroundColor: "#107a3b" };
              } else if (rowData.total_revenue === minRevenue) {
                return { backgroundColor: "#EDF7F1" };
              } else if (
                rowData.total_revenue > minRevenue &&
                rowData.total_revenue <= quarterRevenue
              ) {
                return { backgroundColor: "#c8ebbc" };
              } else if (
                rowData.total_revenue > quarterRevenue &&
                rowData.total_revenue <= midRevenue
              ) {
                return { backgroundColor: "#8dd674" };
              } else if (
                rowData.total_revenue > midRevenue &&
                rowData.total_revenue <= threeQuartRevenue
              ) {
                return { backgroundColor: "#57ad39" };
              } else if (
                rowData.total_revenue > threeQuartRevenue &&
                rowData.total_revenue < maxRevenue
              ) {
                return { backgroundColor: "#428c29" };
              }
            },
          },
          {
            title: "Avg Revenue per Simulation",
            field: "AverageRevenue",
            type: "currency",
            currencySetting: {
              currencyCode: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            },
          },
          {
            title: "Avg Ranking per Simulation",
            field: "AverageRanking",
            type: "numeric",
          },
          {
            title: "Min Set Price",
            field: "MinSetPrice",
            type: "currency",
            currencySetting: {
              currencyCode: "USD",
              maximumFractionDigits: 2
            },
          },

          {
            title: "Max Set Price",
            field: "MaxSetPrice",
            type: "currency",
            currencySetting: {
              currencyCode: "USD",
              maximumFractionDigits: 2,
            },
          },
          {
            title: "Mean Price",
            field: "MeanPrice",
            type: "currency",
            currencySetting: {
              currencyCode: "USD",
              // minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            },
          },
          {
            title: "Autocorrelation Price",
            field: "AutocorrelationPrice",
            type: "number",
            align: "center",
            // currencySetting: {
            //   currencyCode: "USD",
            //   minimumFractionDigits: 0,
            //   maximumFractionDigits: 3,
            // },
          },
          {
            title: "Stockout Frequency",
            field: "StockoutFrequency",
            type: "number",
          },
        ]}
        data={data}
        //data={exampleJSON.duopoly}
      />
    </div>
  );
}
